<template>
  <section>
    <!-- breadcrumb -->
    <div class="breadcrumb-top">
      <b-breadcrumb class="">
        <b-breadcrumb-item :to="{ name: 'dashboard' }">
          <feather-icon
            icon="HomeIcon"
            size="16"
          />
        </b-breadcrumb-item>
        <b-breadcrumb-item>
          {{ $t('Configuration') }}
        </b-breadcrumb-item>
        <b-breadcrumb-item :to="{ name: 'status-code' }" >
          {{ $t('Internal Status Code') }}
        </b-breadcrumb-item>
        <b-breadcrumb-item active >
          {{ $t('Create Code') }}
        </b-breadcrumb-item>
      </b-breadcrumb>
    </div>
    <!-- back button -->
    <b-button
      variant="flat-secondary"
      class="ll-cancel"
      :to="{ name: 'status-code' }"
    >
      <!-- <feather-icon icon="RotateCcwIcon" /> -->
      <b-img
        :src="require('@/assets/images/imgs/return.png')"
        height="auto"
        class="mr-1"
      />
    </b-button>
    <!-- content -->
    <validation-observer ref="baseInfoRules">
    <b-card no-body>
      <b-card-header>
        <b-card-title>{{ $t('Base Info') }}</b-card-title>
        <b-form-checkbox
          v-model="baseInfo.isEnabled"
          class="custom-control-info ll-checkbox"
        >
          {{ $t('Active') }}
        </b-form-checkbox>
      </b-card-header>
      <b-card-body class="ll-cbody">
        <b-row>
          <b-col md='4'>
            <b-form-group
              class="ll-bform"
              label=""
              label-for="statusNo"
            >
              <validation-provider
                #default="{ errors }"
                name="status No"
                rules="required"
              >
                <label class="ll-boldText">{{ $t('Status No') }}</label>
                <b-form-input
                  id="statusNo"
                  v-model="baseInfo.statusNo"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md='4'>
            <b-form-group
              class="ll-bform"
              label=""
              label-for="statusDesc"
            >
            <validation-provider
              #default="{ errors }"
              name="Description"
              rules="required"
            >
              <label class="ll-boldText">{{ $t('Description') }}</label>
              <b-form-input
                id="statusDesc"
                v-model="baseInfo.statusDesc"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md='4'>
            <b-form-group
              class="ll-bform"
              :label="'CN '+ $t('Description')"
              label-for="Description"
            >
              <b-form-input
                v-model="baseInfo.cnDescription"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md='4'>
            <b-form-group
              class="ll-bform"
              label=""
              label-for="intCode"
            >
            <validation-provider
              #default="{ errors }"
              name="Type"
              rules="required"
            >
              <label class="ll-boldText">{{ $t('Type') }}</label>
              <v-select :options="typeList" v-model="baseInfo.statusType" @input="selType"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md='4'>
            <b-form-group
              class="ll-bform"
              :label="$t('Order Status')"
              label-for="intCode2"
            >
              <b-form-input
                v-model="baseInfo.orderStatus"
              />
            </b-form-group>
          </b-col>
          <b-col md='4'>
            <b-form-group
              class="ll-bform"
              :label="$t('Tags')"
              label-for="s"
            >
              <!-- <b-form-input
                v-model="baseInfo.tag"
              /> -->
              <v-select
                v-model="tags1"
                :options="tagsList"
                @input="selTag1"
                label="codeDescription"
                placeholder=""
              ></v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md='4'>
            <b-form-group
              class="ll-bform"
              label=""
              label-for="statusType"
            >
            <validation-provider
              #default="{ errors }"
              name="Internal status code"
              rules="required"
            >
              <label class="ll-boldText">{{ $t('Internal status code') }}</label>
              <b-form-input
                v-model="baseInfo.intCode"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md='4'>
            <b-form-group
              class="ll-bform"
              :label="$t('Remove tags')"
              label-for="s"
            >
              <v-select
                v-model="tags"
                multiple
                :options="tagsList"
                label="codeDescription"
                @input="selTag"
                placeholder=""
              ></v-select>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card no-body>
      <b-card-header>
        <b-card-title>{{ $t('Special setting') }}</b-card-title>
      </b-card-header>
      <b-card-body class="ll-cbody">
        <b-row>
          <b-col lg="4">
            <b-form-group
              :label="$t('Display in track & trace')"
              label-for="s"
              class="ll-bform"
            >
              <div class="ll-radioBox">
                <b-form-radio
                  name="some-radios"
                  v-model="baseInfo.isDisplay"
                  value=true
                >
                  {{ $t('Yes') }}
                </b-form-radio>
                <b-form-radio
                  name="some-radios"
                  v-model="baseInfo.isDisplay"
                  value=false
                >
                  {{ $t('No') }}
                </b-form-radio>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="4">
            <b-form-group
              :label="$t('Count as delivery attempt')"
              label-for="s"
              class="ll-bform"
            >
              <div class="ll-radioBox">
                <b-form-radio
                  name="some-radios1"
                  v-model="baseInfo.isDeliveryAttempts"
                  value=true
                >
                  {{ $t('Yes') }}
                </b-form-radio>
                <b-form-radio
                  name="some-radios1"
                  v-model="baseInfo.isDeliveryAttempts"
                  value=false
                >
                  {{ $t('No') }}
                </b-form-radio>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="4">
            <b-form-group
              :label="$t('Count as return status')"
              label-for="s"
              class="ll-bform"
            >
              <div class="ll-radioBox">
                <b-form-radio
                  name="some-radios2"
                  v-model="baseInfo.isReturn"
                  value=true
                >
                  {{ $t('Yes') }}
                </b-form-radio>
                <b-form-radio
                  name="some-radios2"
                  v-model="baseInfo.isReturn"
                  value=false
                >
                  {{ $t('No') }}
                </b-form-radio>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    </validation-observer>
    <div class="ll-subBtn">
      <b-button
        variant="info"
        @click.prevent="validationForm"
      >
        {{ $t('Save') }}
      </b-button>
    </div>
  </section>
</template>

<script>
import {
  BBreadcrumb,
  BBreadcrumbItem,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BImg, BFormRadio, BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BImg,
    vSelect,
    BFormRadio,
    BFormCheckbox,
  },
  data() {
    return {
      required,
      baseInfo: {
        statusNo: null,
        statusDesc: null,
        intCode: null,
        // intCode2: '',
        // eventOperationCode: '',
        statusType: '',
        // fullNameLocale: '',
        orderStatus: null,
        tag: null,
        isEnabled: false,
        isDisplay: false,
        isReturn: false,
        isDeliveryAttempts: false,
        removeTagsArray: null,
      },
      role: '',
      typeList: ['NORMAL', 'EXCEPTION', 'MESSAGE'],
      tags: null,
      tagsList: [],
      tags1: null,
    }
  },
  computed: {},
  watch: {
    'baseInfo.cnDescription'(val) {
      if (val.length>30){
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Enter a maximum of 30 characters',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
        this.baseInfo.cnDescription = ''
      }
    }
  },
  created() {},
  mounted() {
    this.configTags()
  },
  methods: {
    createStaus() {
      this.$http.post('statusManagement/save', this.baseInfo).then(res => {
        if (res.data.code === '200') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Save Success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$router.push({ name: 'status-code' })
        }
      })
    },
    validationForm() {
      this.$refs.baseInfoRules.validate().then(success => {
        if (success) {
          //  校验成功
          this.createStaus()
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Please check the data',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          return false
        }
        return true
      })
    },
    selType(d) {
      this.baseInfo.statusType = d
    },
    // query tags
    configTags() {
      // this.$http.get('/configTags/findALLConfigTagsByisEnabled').then(res => {
      //   this.tagsList = res.data.data
      // })
      this.$http.get('/masterData/findAllByDataType', { params: { data_type: 'tags'}}).then(res => {
        this.tagsList = res.data.data
      })
    },
    selTag(t) {
      const arr = []
      t.map(item => {
        arr.push(item.codeName)
      })
      this.baseInfo.removeTagsArray = arr
    },
    selTag1(t) {
      // const arr = []
      // t.map(item => {
      //   arr.push(item.codeName)
      // })
      this.baseInfo.tag = t.codeName
      this.tags1 = t.codeDescription
    },
  },
}
</script>

<style scoped>
.ll-subBtn{
  text-align: right;
  height: 50px;
}
.ll-radioBox{
  display: flex;
  justify-content: space-around;
}
</style>
<style>
</style>
